import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout-np';
import SEO from '../../components/SEO/SEO';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from '../../utils/style';
//import PopupSimple from '../../components/shared/PopupSimple';
//import ContactAll from '../../components/Content/Contact/ContactAll';
//import Parser from 'html-react-parser';
import ContentWithFluidImages from '../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Parallax from '../../components/shared/Parallax';
const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      border-radius: 16px;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
  
  @media (max-width: ${breakpoints.sm}) {
    .breadcrumbs, h1 {
      margin: 0 0 20px;
    }
  }
  
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const ContentWrapper = styled('div')`
>p:first-of-type{
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @media(max-width:960px){
    opacity: 0.8;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: normal;
  
  }
}
  >h2:first-of-type{
    text-align:left !important; 
    font-family: Kanit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#fff !important;
  }
  >h2:not(:first-of-type){
    font-family: Kanit;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .flex:nth-child(4){
    @media (max-width:960px ){
      grid-template-areas:
      "C"
      "A"
      "B";
    overflow-x:hidden;
    overflow-y:scroll;
    height:983px;
    }
    padding:28px;
    padding-top:42px;
    border-radius: 8px;
    background-color:rgba(255,255,255,0.08);
    width:100%;
    display:grid;
    grid-template-areas:
    "C C C C"
    "A A B B";
    overflow-x:scroll;
    height: 571px;
    margin-bottom: 48px;
    overflow-y:hidden;
  
    &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: rgba(255,255,255,0.1);
    margin-bottom:10px;
  }
  &::-webkit-scrollbar
  {
    height:8px;
    background-color: rgba(255,255,255,0.1);
  }
  &::-webkit-scrollbar-thumb
  {
    height:8px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    background-color: #fff;
  }


    >.col-3.no-padding:first-child{
      grid-area:A;
    }
    >.col-3.no-padding:not(:first-child){
      grid-area: B;
      
      @media (max-width:960px){
        margin-left:0; 
      }
    }
    >.col-3.casion-box{
      @media (max-width:960px){
        max-height:20%;
        opacity: 0.8;
        font-family: Kanit;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
      }
      font-family: Kanit;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      grid-area: C;
      background:transparent !important;
      border:none;
      padding:0;
      >p{
        margin: 0 ;
      }
      >h2{
        margin-top:0;
      }
    }
    >.col-3.no-padding{
      @media (max-width:960px){
        display:flex;
        flex-direction:column;
        align-items:center;
        >figure{>img{
          padding:0 !important;
          width: 96% !important;
          margin:0 !important;
          margin-top:20px !important;
          margin-bottom:20px !important;
          max-width: 96% !important;
          min-width:0 !important;
          min-height:0 !important;

        }}
      }
      display: flex;
      >figure{

        >img{
          margin-right: 36px;
          padding-right: 24px;
          min-width: 374px;
          min-height: 270px;
      }
    }
    }
  }
  .flex:nth-child(5){
    @media (max-width:600px){
      display:flex;
      flex-direction:column-reverse;    
      >.col-2:first-of-type{
        margin-top:60vw;
        }
    }
    @media (min-width:601px) and (max-width:960px){
      display:flex;
      flex-direction:column-reverse;
      >.col-2:first-of-type{
      margin-top:400px;
      }
      .flex{
        .col-4{
          flex-basis:25%;
        }
      }
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    >div{
      >p:first-of-type{
        margin-top:0;
        margin-bottom: 30px;
      }
      >p{
        font-family: Kanit;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        opacity: 1;
      }
    }
  }
  
  
  .flex {
    margin-bottom: 20px;
  >.col-2{
    >p{
      opacity: 0.8;
      font-family: Kanit;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: normal;
    }
  }
  
  >.col-4{
    display: flex;
    flex-direction: column;
    align-items: center;
    >img{
      max-width: 184px;
      max-height: 208px;
      @media (max-width:417px){
        max-width: 92px;
        max-height: 104px;
      }
    }
    }
  }
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
`;

const PageTemplate = ({ data, pageContext }) => {
  /*const [isOpen, openWindow] = useState(false);

  const openWindowHandler = () => {
    openWindow(!isOpen);
  };

  useEffect(() => {
    const videoPic = document.getElementById('video-handler');
    if (videoPic) {
      videoPic.onclick = openWindowHandler;
    }
  });*/

  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + '- ' + process.env.OWNER;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;
  /*let video = '';
  const videoContent = Parser(currentPage.content, {

    replace: domNode => {
      if (domNode.name === 'video' && domNode.attribs.class === 'about-us-video') {
        video = <video style={{ width: '100%', height: '100%' }} autoPlay className="about-us-video"
                       poster="https://api.dev.gclub-casino.com/wp-content/uploads/2019/07/Rectangle1.png"
                       src="http://api.loc/wp-content/themes/gclub-theme-v1-0-1/media/lnwAsia-Call-Center-2.mp4"
                       controls="controls" width="354" height="264"/>;
      }
    },
  });*/
  useEffect(() => {
    document
      .getElementsByClassName('flex')[1]
      ?.addEventListener('click', () => {
        let qwe = event.target.scrollTop;
        console.log(qwe);
      });
  });
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      {/*isOpen && (
          <PopupSimple title="Call center" closeHandler={openWindowHandler}>
            {video}
          </PopupSimple>
        )*/}
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper>
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : currentPage.title
              }
            />
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper>
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ? (
            ContentWithFluidImages(currentPage.content, media)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          )}
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: {
        wordpress_id: { in: $related }
        acf: { post_template: { ne: "service_post_carousel" } }
      }
      limit: 4
    ) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
