import React, {useState} from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { reset, typography, breakpoints, color } from '../../utils/style';
import Header from '../Header/HeaderNew';
import Breadcrumbs from './Breadcrumbs';
import Footer from '../Footer/Footer';
import PopupWindow from './PopupWindow';
import {getAuthUserName} from "../Auth/Utils";
import StoreContext from './Context/StoreContext.js'

const Content = styled('div')`
  display: flex;
  min-height: 100vh;
  flex-direction: column; 
  background-position: bottom right;
  &.inter-blocked, &.popup-blocked {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,0.7);
      z-index: 5;
      backdrop-filter: blur(10px);
    }
    #toFix {
      z-index: 0;
    }
  }
  &.bg-added {
    background: ${color.brownRed};
    header {
     background: ${color.brownRed};
    }
  }
  &.popup-blocked {
    .nav-wrap {
      position: static;
    }
    #app-header, .logo {
      z-index: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: relative;
  }
`;

const AllContent = styled('div')`
  flex-grow: 1;
`;

const Main = styled('main')`
  
`;

export default ({ children }) => {
  const [username, setUsername] = useState(getAuthUserName());
  return([
    <Global key="reset" styles={css`${reset} ${typography}`}/>,
    <StoreContext.Provider key="login-context-provider" value={{username:username, setUsernameCallback: setUsername}}>
      <Content key="app-content" id="all-content">
        <AllContent key="app-content-block">
          <Header key="app-header" id="app-header" location="/"/>
          <Main key="app-content-main">
            {children}
          </Main>
        </AllContent>
        <Footer key="app-footer"/>
        <PopupWindow key="popup-window" title="Popup window" />
      </Content>
    </StoreContext.Provider>
  ])}